
.boxfooter{
    position: fixed;
    bottom: 0;
    background-color: var(--gray-900);
    margin-left: 60px;
    width: 100%;
    height: 15px;
    display: flex;
    justify-content: center;
}



.footer{
    display: flex;
    align-items: center;
}

.footer > span{
    color: white;
    font-size: 13px;
    font-style: italic;
}

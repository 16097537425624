
.cadboxContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
}


.cadcontainersection{
    width: 100%;
    height: 100vh;
    display: flex;
}

.caddiv-login{
    background-color: white;
    height: 100vh;
    width: 50%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}

.cadboxform{
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 90%;
    max-width: 550px;
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
}

form.cadboxform a span{
    color: black;
    font-weight: bold;
}

.cadbox-text-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;

}

.cadbox-text-info > h4{
    font-size: 18px;
    font-style: italic;
}

.cadbox-text-info > h6{
    font-size: 12px;
    font-style: italic;
}

.cadcontainerinput{
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.caddiv-input{
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 5px;
    gap: 5px;
}

.caddiv-input > svg{
    margin-left: 5px;
}

.caddiv-input > svg:hover{
    cursor: pointer;
}

.caddiv-input > input{
    width: 100%;
    border: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.cadboxform > button{
    width: 80%;
    margin-top: 5px;
    margin-bottom: 50px;
}

.cadboxform > a{
    color: rgba(0, 0, 0, 0.7);
    font-style: italic;
}

.cadboxform > a:hover{
    text-decoration: underline;
}

.recaptcha{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

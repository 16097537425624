
.content-listapaciente{
    margin: 5px 5px 5px 65px;
    height: calc(100vh - 93px);
}

.container-titulo-paciente{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    height: 40px;    
}

.div-titulo-tela{
    max-width: 1280px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-btn-novo{
    display: flex;
    align-items: center;
    height: 40px;
}

.div-btn-novo > a{
    background-color: var(--green100);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 30px;
    border-radius: 5px;
}

.div-btn-novo > a:hover{
    background-color: green;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.3);
}

.container-filtro-paciente{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-self: flex-start;
    border-bottom: 2px solid #ccc;
}

.div-campos-input{
    width: 100%;
    max-width: 1280px;
    height: inherit;
    display: flex;
    align-items: center;
}

.div-filtrar{
    width: 500px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
}

.div-filtrar > label{
    margin-bottom: 3px;
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #555;
}

.div-filtrar > input{
    border-color: #ccc;
    width: 100%;
} 

.div-filtrar > input:focus{
    background-color: var(--gray-50);
    border-color: #aaa;
} 

.div-sempaciente{
    width: 100%;
    max-width: 1280px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-sempaciente > span{
    font-style: italic;
    color: #bbb;
    font-size: 26px;
}

.div-gridview{
    width: 100%;
    max-width: 1280px;
    height: calc(100vh - 238px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.gridview-header{
    background-color: var(--marrom);
    color: white;
    height: 35px;
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gridview-header span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-right: 1px solid #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
}

.foto > img{
    object-fit: cover;
    height: 98%;
}

.c1{
    width: 10%;
}

.c2{
    width: 40%;
}

.c3{
    width: 20%;
}

.c4{
    width: 10%;
}

.c5{
    width: 10%;
}

.c6{
    width: 10%;
}

.gridview-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
}

.ocultarLinha{
    display: none !important;
}

.div-linha{
    display: flex;
    height: 80px;
    width: 100%;
}

.considerado{
    background-color: rgba(255, 0, 0, 0.3) !important;
}

.div-linha:nth-child(even) {
    background-color: #fff;
}

.div-linha:hover{
    background-color: var(--gray-50);
}

.div-linha > span > img{
    border-radius: 50%;
    width: 60%;
    object-fit: cover;
}

.linha{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-size: 12px;
    cursor: default;    
}

.container-nome-idade{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
}

.container-nome-idade h3{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-style: italic;
    color: #000;
}

.container-nome-idade span {
    font-size: 14px;
    font-style: italic;
}

.negrito{
    font-weight: bold;
    text-decoration: underline;
}

.span-generico{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    font-size: 14px;
    font-style: italic;
    color: #000;    
}

.div-btn-acao{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 5px;
}

.btn-acao{
    border: none;
    background-color: transparent;
}



.container-loading{
    width: 100%;
    height: calc(100vh - 85px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    height: 200px;
    width: 200px;
    flex-direction: column;
}

.loading h2{
    color: #555;
    font-style: italic;
    margin-top: 10px;
}

.loading svg{
    animation: loadingsvg 5s linear infinite;
}

@keyframes loadingsvg {
    from{
        rotate: 0deg;
    }
    to{
        rotate: 360deg;
    }
}
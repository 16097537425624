

.content-modal-historico{
    background-color: #fafafa;
    position: fixed;
    width: 50%;
    height: 50%;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.div-span{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-span span{
    font-size: 18px;
    font-weight: bold;
    cursor: default;
    font-style: italic;    
}

.div-historico{
    margin-top: 10px;
    height: 85%;
    overflow-y: auto;
}

.div-header-historico{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--marrom);
    color: white;
    height: 20px;  
    border-radius: 3px; 
    padding: 0px 5px;
}


.div-header-historico > span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
    width: 25%;
}

.div-valores-historico{
    display: flex;
    align-items: center;
    width: 100%;
}

.div-valores-historico span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: default;   
    width: 25%; 
}

.div-valores-historico span:last-child{
    justify-content: flex-end !important;
    padding-right: 30px;
}
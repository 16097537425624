
.div-orcamento-pagamento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.div-pagamento span{
    font-style: italic;
    font-size: 15px;
}

.div-pagamento p{
    font-size: 15px;
    font-weight: bold;
}
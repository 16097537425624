
.gridview-header-prod{
    background-color: var(--marrom);
    color: white;
    height: 35px;
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gridview-header-prod > span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-right: 1px solid #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
}

.p1{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p1 > img{
    object-fit: cover;
    height: 98%;
}

.p2{
    width: 50%;    
}

.p3{
    width: 15%; 
}

.p4{
    width: 15%;   
}

.p5{
    width: 10%;
}

.gridview-body-product{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
}

.div-linha-prod{
    display: flex;
    height: 70px;
    width: 100%;
}

.div-linha-prod:nth-child(even) {
    background-color: #fff;
}

.div-linha-prod:hover{
    background-color: var(--gray-50);
}

.div-linha-prod > span > img{
    border-radius: 50%;
    width: 80%;
    object-fit: cover;
}

.right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: bold;
}


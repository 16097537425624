
.quemsomos-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 600px;
    margin-top: 5px;
    overflow: auto;
}

.quemsomos-container div img{
    width: 300px;
    object-fit: cover;
    margin-right: 20px;
}

.quemsomos-container div p{
    font-size: 30px;
    text-align: justify;
    padding: 0 20px 0 20px;
    margin-bottom: 30px;
    font-family: "Kalam", serif;
    font-weight: 700;
    font-style: normal;   
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .quemsomos-container{
        flex-direction: column;
        margin-top: 10px;
        height: 600px;
    }

    .quemsomos-container div img{
        width: 200px;
    }

    .quemsomos-container div p{
        margin-top: 20px;
        font-size: 15px;
    }
}
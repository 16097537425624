

.div-titulo-meses{
    height: 42px;
    margin-top: 5px;
    border: 1px #aaa solid;
    border-radius: 5px;
    background: linear-gradient(65deg, var(--blue), var(--blue100), var(--marrom));
    overflow: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-agendar{
    width: 170px;
    height: 39px;
    border: 0;
    background-color: var(--green100);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn-agendar span{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.btn-agendar:hover{
    background-color: green;
}

.div-titulo-meses .titulo-ano{
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-titulo-meses .titulo-ano span{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.div-titulo-meses ul{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 20px;
    height: 40px;
}

.div-titulo-meses ul li{
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
}

.div-titulo-meses ul li:hover{
    background-color: rgb(190, 52, 52);
}

.mesatual{
    background-color: brown;
    box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.3);
    transform: scale(1.02);
    font-style: italic;
}

.div-container-agenda{
    height: calc(100vh - 185px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
}

.titulo-mes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(150, 150, 150, 0.4);
}

.titulo-mes > span{
    margin: 10px 0px;
    color: var(--blue);
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
}

.titulo-mes > svg:first-child{
    margin-left: 10px;
}

.titulo-mes > svg:last-child{
    rotate: 180deg;
    margin-right: 10px;
}

.titulo-mes > svg:hover{
    border: 1px solid var(--marrom);
    border-radius: 5px;
    cursor: pointer;
}

.div-calendario{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.div-container-calendario{
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table-grid{
    width: 98%;
    height: 90%;
    margin: 0 auto;
}

.table-head{
    height: 40px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.table-head tr{
    background-color: var(--marrom);
    height: 40px;
    width: 14%;
}

.table-head tr td{
    color: #fff;
    text-align: center;
}

.body-calendar tr td{
    text-align: center;
    color: #000;
    font-size: 20px;
}

.body-calendar tr td span{
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.body-calendar tr td span:hover{
    background-color: #aaa;
}

.hoje{
    background-color: var(--blue100);
    color: #fff;  
}

.atendimento{
    background-color: brown !important; 
    color: white; 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);  
}

.div-pacientes{
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
}

.div-titulo-agenda{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid #ccc;
}

.div-titulo-agenda span{
    font-style: italic;
    font-size: 15px;
}

.div-pacientes ul{
    list-style: none;
    margin: 2px 0px 0px 5px;
}

.li-container{
    border: 1px solid #ccc;
    border-radius: 2px 5px 5px 2px;
    height: 120px;
    margin-bottom: 5px;
    display: flex;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.considerado{
    background-color: #fdd2d2 !important;
}

.div-atendimento{
    overflow-y: auto;
}

.li-nivel{
    height: 100%;
    width: 20px;
    background-color: brown;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.li-info-atendimento{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.li-linha-titulo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-left: 10px;
}

.li-linha-titulo span{
    font-size: 19px;
    font-weight: bold;
}

.li-info-atendimento > span{
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
}

.li-div-tratamento{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    margin: 10px 0px 0px 10px;
}

.li-div-tratamento span{
    font-size: 13px;
    font-style: italic;
}

.li-btn{
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-right: 2px;
}

.li-btn .btn-finalizar{
    border: 0;
    height: 30%;
    width: 100%;
    background-color: var(--blue100);
    border-radius: 4px;
    color: white;
    font-weight: bold;
    gap: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.li-btn .btn-finalizar:hover{
    background-color: var(--blue);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);    
}

.li-btn .btn-editar{
    border: 0;
    height: 30%;
    width: 100%;
    background-color: var(--green100);
    border-radius: 4px;
    color: white;
    font-weight: bold;
    gap: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.li-btn .btn-editar:hover{
    background-color: green; 
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);   
}

.li-btn .btn-cancelar{
    border: 0;
    height: 30%;
    width: 100%;
    background-color: brown;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    gap: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.li-btn .btn-cancelar:hover{
    background-color: red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.agenda-vazia{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.agenda-vazia span{
    font-size: 16px;
    font-style: italic;
    color: #bbb;
}

.div-request-horario{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.isloading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.isloading svg{
    margin-bottom: 20px;
    animation: loadingsvg 5s linear infinite;
}

.isloading span{
    font-size: 20px;
    color: #555;
    font-style: italic;
}

@keyframes loadingsvg {
    from{
        rotate: 0deg;
    }
    to{
        rotate: 360deg;
    }
}

.content-dashboard{
    margin: 5px 5px 5px 65px;
    height: calc(100vh - 93px);
}
  

.dash-div-home{
    margin-top: 2px;
    margin-right: 1px;
    height: calc(100vh - 88px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: auto;
}

.div-container-titulo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    height: 40px;
}

.textheader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    width: 50%;
}

.textheader > svg{
    margin: 0px 10px;
}

.textheader > h3{
    font-family: sans-serif;
    font-style: italic;
    color: #555;
}

.container-dashboard{
    height: 170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1000px;
    margin: 0 auto;
    transition: all 0.2s;
    padding-left: 5px;
    padding-right: 5px;
}

.box-dash{
    height: 150px;
    width: 25%;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.box-dash:not(:last-child){
    margin-right: 10px;
}

.info-pedido{
    background-color: rgba(0, 0, 255, 0.6);
    width: 100%;
    height: 130px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.info-pedido > h3{
    color: white;
    font-size: 35px;
    font-family: cursive;
}

.info-pedido:hover svg{
    transform: scale(1.4);
    transition: all 0.5s;
}

.info-pedido svg{
    transform: scale(1);
    transition: all 0.5s;
}

.legenda{
    height: 20px;
    width: 100%;
    background-color: rgba(0, 0, 255, 0.8);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legenda > span{
    color: white;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
}

.receber{
    background-color: rgba(0, 128, 0, 0.6);
}

.leg-receber{
    background-color: rgba(0, 128, 0, 0.8);
}

.servico{
    background-color: rgba(255, 166, 0, 0.6);
}

.leg-servico{
    background-color: rgba(255, 166, 0, 0.8);
}

.pagar{
    background-color: rgba(255, 0, 0, 0.6);
}

.leg-pagar{
    background-color: rgba(255, 0, 0, 0.8);
}

.img-logotipo{
    opacity: 0.3;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    z-index: -1;
}



.ul-itens{
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: 95%;
}

.ul-itens > li{
    margin-right: 5px;
    margin-top: 2px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
}

.ul-itens > li:hover{
    background-color: rgba(255,202,102,0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.ul-itens span{
    background-color: #ccc;
    width: 100%;
    height: 2px;
    margin-top: 1px;
}

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body{
  font-family: sans-serif;
  height: calc(100vh - 160px);
}

:root{
  --background: #f3f3f3;
  --blue: #2322AE;
  --dark-700: #1d1d2e;
  --gray-50:  #E6E6E6;
  --gray-100: #8a8a8a;
  --gray-900: #616161;

  --marrom: #E09B77;
  --blue100: #6060E0;
  --green900: #6DE04A;
  --green100: #339933;
}

body{
  background-color: var(--background);
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
}

input[type='text'], input[type='search']{
  text-transform: uppercase;
  font-size: 13px;
}

input[type='text']:focus, input[type='date']:focus, input[type='email']:focus, input[type='password']:focus{
  background-color: var(--gray-50);
}

textarea{
  font-family: sans-serif;
  text-transform: uppercase;
}

select:focus{
  background-color: var(--gray-50);
}

textarea:focus{
  background-color: var(--gray-50);
}
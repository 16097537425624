
.content-modal-baixar{
    background-color: #fafafa;
    position: fixed;
    width: 30%;
    height: 50%;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    overflow: auto;
}

.div-container-valores{
    border: 2px solid #aaa;
    height: 68%;
    border-radius: 10px;
    margin: 20px 0px 5px 0px;
    overflow: auto;
}

.div-valor{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    height: 40px;
    margin: 5px 0px;
}

.div-valor label{
    color: #555;
    font-size: 22px;
    width: 230px;
    text-align: right;
    font-weight: bold;
    font-style: italic;
}

.div-valor #valorconta, #valorpagar{
    color: blue;
    font-size: 22px;
    font-weight: bold;
}

.div-valor #valoradiantado{
    color: red;
    font-size: 22px;
    font-weight: bold;
}

.div-valor > input{
    font-weight: bold;
    font-size: 15px;
    width: 130px;
}

.div-cont-btn{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.div-cont-btn button{
    height: 35px;
    width: 150px;
    border: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
}

.div-cont-btn #btn-baixar{
    background-color: var(--blue100);
}

.div-cont-btn #btn-baixar:hover{
    background-color: blue;
}

.div-cont-btn #btn-cancelar{
    background-color: brown;
}

.div-cont-btn #btn-cancelar:hover{
    background-color: red;
}
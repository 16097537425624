
.content-modal-desfazer{
    background-color: #fafafa;
    position: fixed;
    width: 25%;
    height: 40%;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}
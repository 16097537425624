

.banner-container{
    height: 410px;
    width: 100%;
    background-color: #fff;
}

.banner-img{
    width: 100%; 
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

/* MOBILE */

@media screen and (max-width: 980px) {
    
    .banner-container{
        height: 300px;
    }

    .banner-img{
        height: 300px;
        padding-left: 10px;
        padding-right: 10px;
    }    
}

.btnBack{
    display: flex;
    align-items: center;
    border: 0;
    margin-left: 10px;
    padding: 3px;
    border-radius: 5px;
}

.btnBack:hover{
    background-color: #ccc;
}

.tabs{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 100%;
    max-width: 1280px;
}

.tablinks{
    border: 1;
    height: 100%;
    width: 250px;
    padding: 5px;
    border: 0;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
    transition: all 0.3s;
}

.tablinks:hover{
    background-color: #eee;
}

.tablinks.active{
    border-bottom: 3px solid var(--blue100);
    background-color: #ddd;
    font-weight: bold;
    color: var(--blue100);
    font-size: 12px;
    transition: all 0.3s;
}

.div-inputs{
    display: flex;
    flex-direction: column;
}

.div-inputs label, .div-inputs span{
    margin-bottom: 3px;
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #555;
}

.div-inputs input{
    color: #000;
}

.div-inputs select{
    height: 35px;
    border-radius: 5px;
}

.div-inputs textarea{
    resize: none;
    height: 120px;
    width: 765px;
    padding: 5px;
    border-radius: 5px;
    border-color: #ccc;
}

/* INFO PACIENTE TAB 1 */

.formpaciente{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);
    max-width: 1280px;
    width: 100%;
    overflow-y: auto;  
}

.form-div-linha{
    height: 140px;
    display: flex;
    padding: 10px;
}

.form-div-normal{
    height: 80px;
    display: flex;
    padding: 10px;
    gap: 20px;
}

.foto-paciente{
    height: 120px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    padding: 8px;
}

.foto-paciente > img{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.foto-paciente input{
    display: none;
}

.form-linha{
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-left: 15px;
}

.form-linha .div-inputs{
    display: flex;
    flex-direction: column;
}

.form-linha label{
    margin-bottom: 3px;
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #555;   
}

.form-linha1{
    display: flex;
    width: 100%;
    gap: 20px;
}

/* FIM TAB1 */

/* INFO PACIENTE TAB 2*/

.div-tab2{
    max-width: 1280px;
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    align-items: flex-start;
    padding: 20px 0 0 20px;
    gap: 20px;
}
/* FIM TAB 2*/

.div-cpf{
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 35px;
}

.div-cpf > input{
    border: 0 !important;
    height: 33px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

#search-cep{
    cursor: pointer;
}

#nome{
    text-transform: uppercase;
}

.div-btn-paciente{
    height: 55px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.div-btn-paciente button{
    border: 0;
    height: 40px;
    width: 150px;
    background-color: brown;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-btn-paciente > button:hover{
    background-color: red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.div-btn-paciente > button > svg{
    margin-right: 5px;
}

.div-btn-paciente > button#salvar{
    background-color: #22B14C;
}

.div-btn-paciente > button#salvar > svg{
    margin-right: 5px;
}

.div-btn-paciente > button#salvar:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* tab3 */

.div-tab3{
    max-width: 1280px;
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn-aprovar{
    background-color: var(--blue100) !important;
}




.pd1{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pd1 > img{
    object-fit: cover;
    height: 98%;
    border-radius: 50%;
}

.pd2{
    width: 60%;    
}

.pd3{
    width: 20%; 
}

.pd4{
    width: 10%;   
}


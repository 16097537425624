@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.planos-container{
    height: 300px;
    width: 100%;
    margin-top: 5px;
}

.planos-texto-container{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.planos-texto-container h1{
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    font-size: 48px;
}

.planos-texto-container h3{
    margin-bottom: 40px;
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    color: #8a8a8a;
}

.planos-texto-container h4{
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    color: #8a8a8a;
    width: 65%;
}

.planos-valores{
    height: 620px;
}

.div-planos-valores{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
}

.div-plano1, .div-plano2{
    background-color: #D9D9D9;
    height: 600px;
    width: 30%;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.div-plano3{
    background-color: #b1b1b1;
    height: 600px;
    width: 30%;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.div-plano1 h2, .div-plano2 h2, .div-plano3 h2{
    text-align: center;
    color: #737373;
    font-style: italic;
    padding: 5px;
    height: 35px;
}

.plano-mod3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.div-plano1:hover, .div-plano2:hover{
    background-color: #ccc;
}

.div-plano3:hover{
    background-color: #a1a1a1;
}

.div-plano3 h2{
    color: #fff !important;
}

.plano-valores{
    height: 85%;
    display: flex;
    flex-direction: column;
}

.plano-div-valores{
    text-align: center;
}

.plano-div-valores span.spanvalor{
    font-size: 38px;
    color: #737373;
    font-weight: bold;
}

.plano-div-valores span.spanmes{
    font-size: 12px;
    color: #737373;
    font-style: italic;
}

.plano-valores a{
    border: 0;
    background-color: var(--blue100);
    height: 40px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.plano-valores a:hover{
    background-color: var(--blue);
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}

.plano-valores ul{
    list-style: none;
}

.plano-valores ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0 15px 0;
    padding-left: 18px;
    color: #737373;
    font-size: 18px;
    font-family: "Inter", serif;
    font-optical-sizing: auto;
}

.plano-valores ul li svg{
    margin-right: 10px;
}

.plano-valores ul.ul-mod3 li{
    color: #000;
}

.plano-div-valores span.spanvalor3{
    font-size: 38px;
    color: #000;
    font-weight: bold;
}

.plano-div-valores span.spanmes3{
    font-size: 12px;
    color: #000;
    font-style: italic;
}

.planos-txtbtn{
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--blue100);
    height: 35px;
    width: 90%;
    margin: 10px auto;
    border-radius: 25px;
    opacity: 0;
}

.planos-txtbtn span{
    color: #fff;
    font-weight: bold;
    font-size: 13px;
}


/* MOBILE */

@media screen and (max-width: 980px) {

    .planos-texto-container h1{
        font-size: 22px;
        text-align: center;
        width: 100%;
    }

    .planos-texto-container h3{
        font-size: 14px;
        width: 100%;
        text-align: center;
    }

    .planos-texto-container h4{
        font-size: 14px;
        width: 90%;
        margin: 0 auto;
        text-align: justify;
    }

    .planos-valores{
        height: auto;
    }

    .div-planos-valores{
        flex-direction: column;
        gap: 10px;
    }

    .div-plano1, .div-plano2, .div-plano3{
        width: 90%;
    }

    .plano-valores a{
        display: none;
    }

    .planos-txtbtn{
        display: flex;
        opacity: 1;
    }
}
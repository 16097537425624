
.boxImg{
    display: flex;
    margin: 5px;
    height: 142px;
}

.boxImg > label{
    cursor: pointer;  
    border: 1px dashed rgba(0, 0, 0, 0.3);
    height: 142px;
    border-radius: 5px;
}

#img-user{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-right: 10px;
}

.boxImg > label > img{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.boxImg > label:last-child{
    border: 1px dashed rgba(0, 0, 0, 0.3);
    object-fit: cover;
    width: 500px;
    height: 140px;
    border-radius: 5px;
    margin-left: 15px;
}

.boxImg #logotipo{
    object-fit: cover;  
    width: 500px; 
    height: 140px; 
    border-radius: 5px;
    margin: 0;
    
}

.txtLogo{
    position: absolute;
    color: rgba(209, 209, 209, 0.6);
    margin-left: 4px;
    margin-top: 4px;
    width: 490px;
    text-align: center;    
}

.div-btn-logo{
    display: flex;
    flex-direction: column;
    width: 70px;
    gap: 10px;
    margin-left: 10px;
}

.btnLogo{
    z-index: 99;
    padding: 5px;
    background-color: #ccc;
    border-radius: 3px;
    border: 0;
}

.btnLogo:hover{
    background-color: var(--green900);
    color: white;
}

.boxImg > label > input{
    display: none;
}

.boxInputs{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 290px);
    width: 100%;
    max-width: 1280px;
    margin-left: 5px;
    overflow: auto;
}

.div-perfil-linha{
    display: flex;
    margin: 5px 0px;
    gap: 20px;
}

.div-btn-perfil{
    height: 55px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.div-btn-perfil button{
    border: 0;
    height: 40px;
    width: 150px;
    background-color: brown;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-btn-perfil > button:hover{
    background-color: red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.div-btn-perfil > button > svg{
    margin-right: 5px;
}

.div-btn-perfil > button#salvar{
    background-color: #22B14C;
}

.div-btn-perfil > button#salvar > svg{
    margin-right: 5px;
}

.div-btn-perfil > button#salvar:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}





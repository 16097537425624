
section.container-finalizar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
}

.content-finalizar{
    position: fixed;
    top: 5%;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 82%;
    width: 45%;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    cursor: default !important;
}

.grupo-btn > #select-forma{
    height: 35px;
    width: 150px;
    border-radius: 5px;
    margin-right: 5px;
}

.grupo-btn > #select-forma:focus{
    background-color: #ccc;
}

#fechar-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}

#fechar-modal:hover{
    transform: scale(1.1);
}

#finalizar-atend{
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green100);
}

#finalizar-atend > svg{
    margin-right: 5px;
}

#finalizar-atend:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.loading-finalizar{
    display: flex;
    align-items: center;
}

.loading-finalizar > svg{
    margin-right: 5px;
    animation: loadingsvg infinite 3s linear;
}

.loading-finalizar > span{
    font-size: 15px;
    font-style: italic;
}

.boxcontent-dados{
    width: 100%;
}

.dados-paciente{
    display: flex;
    flex-direction: column;
    margin: 15px 0px 20px 30px;
}

.dados-paciente > small{
    font-style: italic;
    font-size: 15px;
}

.dados-paciente > span{
    font-weight: bold;
    font-size: 15px;
}

.trat-realizado{
    display: flex;
    flex-direction: column;
    width: 90%; 
    margin-left: 30px;   
}

.trat-realizado > label{
    font-size: 15px;
    font-style: italic;
}

.trat-realizado > textarea{
    height: 150px;
}

/* inicio itens */

.container-itens{
    margin: 15px 0px 0px 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.container-itens > label{
    font-size: 15px;
    font-style: italic;    
}

.div-itens{
    border: 1px dotted #000;
    display: flex;
    padding: 10px;
    overflow-y: auto;
}

.up{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.down{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 0px !important;
}

.div-itens label{
    font-size: 14px;
    font-style: italic;  
}

.box-select{
    width: 50%;
}

.div-select{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.div-select select{
    height: 35px;
    width: 100%;
    border-radius: 5px;
}

.box-input{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-camposfin{
    display: flex;
    flex-direction: column;
}

.div-camposfin input{
    width: 90%;
}

.div-camposfin label, .div-select label{
    font-size: 14px;
}

.div-camposfin button{
    width: 55px;
    height: 35px;
    margin-top: 15px;
    border-radius: 5px;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green100);
}

.div-camposfin button:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

/* inicio so itens adicionado */

.div-griditens{
    margin-top: 10px;
    border: 1px solid #ccc;
    height: 150px;
    overflow-y: auto;
}

.grid-header-itens{
    background-color: var(--marrom);
    color: white;
    height: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.grid-header-itens > span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border-right: 1px solid #fff;
    font-size: 11px;
    font-weight: bold;
    cursor: default;
}

.i1{
    width: 40%;
}

.i2{
    width: 20%;
}

.i3{
    width: 10%;
}

.align-right{
    text-align: right;
    padding-right: 5px;
}


.grid-body-itens{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border-right: 1px solid #fff;
    font-size: 13px;
    cursor: default;   
}

.grid-body-itens:nth-child(even) {
    background-color: #eee;
}

.grid-body-itens:hover{
    background-color: #ccc;
}

/* conatiner valores */

.container-valores{
    border: 1px dotted rgba(0, 0, 0, 0.6);
    width: 100%;
    margin-top: 20px;
    height: 100px;
    border-radius: 5px;
    overflow-y: auto;
}

.valor-cobrado{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    flex-wrap: wrap;
}

.dv-formapagto{
    display: flex;
    flex-direction: column;
}

.dv-formapagto > label, .dv-formapagto > h5{
    font-size: 15px;
    font-style: italic;
    font-weight: normal;
}

.dv-formapagto > select{
    height: 35px;
    width: 150px;
    border-radius: 5px;
}

.dv-formapagto > select:focus{
    background-color: #ccc;
}

.dv-valor{
    display: flex;
    flex-direction: column;
}

.dv-valor > label{
    font-size: 15px;
    font-style: italic;
}

.dv-valor > input{
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    width: 150px;
}

.obs-pagamento{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.obs-pagamento > label{
    font-size: 15px;
    font-style: italic;    
}

.obs-pagamento > textarea{
    margin-bottom: 15px;
}

/* inicio totais */

.div-totais{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.div-totais span{
    font-size: 15px;
}

.div-totais span:last-child{
    font-weight: bold;
}

@keyframes loadingsvg {
    from{
        rotate: 0deg;
    }
    to{
        rotate: 360deg;
    }
}



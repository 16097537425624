
.container-filtro-prontuario{
    height: 150px;
    width: 100%;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #ccc;
}

.div-filtro-inputs{
    width: 100%;
    max-width: 1280px;
    height: 80px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 15px 0 0 15px;
}

.container-prontbtn{
    display: flex;
    padding: 15px 0 0 15px;
    width: 100%;
    max-width: 1280px;
    height: 70px;
    gap: 20px;
}

.container-prontbtn > button{
    font-size: 15px;
    height: 40px;
    width: 180px;
}

.container-prontbtn > #btnPesqPront{
    background-color: var(--marrom);
    font-size: 14px;
}

.container-prontbtn > #btnPesqPront:hover{
    background-color: #ca6b3c;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.4);
}

.container-prontbtn > #btnPrintPront{
    background-color: #6060E0;
    margin-left: 10px;
    font-size: 14px;
}

.container-prontbtn > #btnPrintPront:hover{
    background-color: blue;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.4);
}

.container-prontbtn > button > svg{
    margin-right: 8px;
}

.div-qtdatendimento{
    padding-left: 15px;
}

.div-qtdatendimento > span{
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #555;
}

.div-container-cards{
    display: flex;
    width: 100%;
    max-width: 1280px;
    height: calc(100vh - 290px);
    overflow: auto;
    padding: 10px;
}

.div-semprontuario{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-semprontuario span{
    margin-top: 10px;
    font-style: italic;
    font-size: 18px;
    font-weight: bold;
    color: #555;
}

.card-linha{
    width: 100%;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.4);
    overflow: auto; 
}

.card:hover{
    background-color: #ddd;
}

.card span{
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    text-align: center;
    margin: 0 5px 0 5px;
}

.card > button{
    border: 0;
    padding: 5px;
    background-color: var(--marrom);
    border-radius: 3px;
    color: white;
    width: 90%;
    height: 30px;
}

.card > button:hover{
    background-color: #b8683c;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.4); 
}
/*   */













/* .card{
    border: 1px solid #a8a8a8;
    background-color: rgb(250, 250, 250);
    width: 100px;
    height: 120px;
    margin-left: 10px;
    margin-top: 10px;  
    border-radius: 5px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.4); 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; 
}

.card > svg{
    margin-top: 10px;
}

.card > button{
    margin-bottom: 10px;
    border: 0;
    padding: 5px;
    background-color: var(--marrom);
    border-radius: 3px;
    color: white;
}

.card > button:hover{
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.4); 
} */
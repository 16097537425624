
.box-modal{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
}

.content-modal{
    background-color: white;
    position: fixed;
    width: 450px;
    height: 300px;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.btnCloseModal{
    display: flex;
}

.btnCloseModal > button{
    border: 0;
    background-color: red;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.btnCloseModal > button > svg{
    margin-top: 3px;
}

.btnCloseModal > button:hover{
    transform: scale(1.1);
}

.headerModal{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerModal > h3{
    margin-top: 10px;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    cursor: default;
}

.headerModal > h2{
    margin-top: 10px;
    font-size: 16px;
    cursor: default;
}

.contentButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
}

.contentButton #ok{
    border: 0;
    padding: 10px;
    height: 40px;
    width: 150px;
    background-color: #d32b2b;
    border-radius: 5px;
    color: white;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentButton svg{
    margin-right: 5px;
}

.contentButton #ok:hover{
    background-color: red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.contentButton #cancel{
    border: 0;
    padding: 10px;
    height: 40px;
    width: 150px;
    background-color: var(--marrom);
    border-radius: 5px;
    color: white;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentButton #cancel:hover{
    background-color: #df814f;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}




.content-modalprontuario{
    background-color: white;
    position: fixed;
    width: 40%;
    height: 60%;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.btn-close{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-close:hover{
    transform: scale(1.1);
}

.grupo-btn > span{
    font-style: italic;
}

.div-tratamento{
    margin-top: 10px;
}

.div-tratamento > span{
    font-style: italic;
    font-size: 15px;
}

.div-tratamento > P{
    border: 1px solid rgba(0, 0, 0, 0.5); 
    height: 200px; 
    border-radius: 5px; 
    padding: 10px;
    overflow-y: auto;
}

.div-realizado{
    margin-top: 10px;
}

.div-realizado > span{
    font-style: italic;
    font-size: 15px;
}

.div-realizado > p{
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 200px;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
}
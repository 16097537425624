
.container-filtro-contasreceber{
    height: 100px;
    width: 100%;
    border-bottom: 2px solid #ccc;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.div-input-filtro{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    height: 95%;
}

.div-total{
    height: 100%;
    width: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.div-total label{
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #555;
}

.div-total span{
    color: #555;
    font-size: 26px;
}

/* TABS */

.tabscontas{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 100%;
    max-width: 1280px;
}

.tab-receber{
    max-width: 1280px;
    width: 100%;
    height: calc(100vh - 275px);
    margin-top: 1px;
    overflow: auto;
}

.div-linha-receber{
    display: flex;
    height: 30px;
    width: 100%;
}

.div-linha-receber:nth-child(even) {
    background-color: #fff;
}

.div-linha-receber:hover{
    background-color: var(--gray-50);
}

.cr1{
    width: 26%;
}

.cr2{
    width: 7%;
}

.cr3{
    width: 5%;
}

.cr4{
    width: 7%;
}

.cr5{
    width: 13%;
}

.cr6{
    width: 10%;
}

.cr7{
    width: 10%;
}

.cr8{
    width: 10%;
}

.cr9{
    width: 12%;
}

.alinha-valor{
    justify-content: flex-end !important;
    padding-right: 5px;
}

.saldo{
    justify-content: flex-end !important;
    background-color: rgb(202, 252, 202);
    color: #fff;
    padding-right: 5px;
    color: #000;
    font-weight: bold;
}

.div-btn-baixar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.div-btn-baixar .btn-det{
    border: 0;
    background-color: var(--blue100);
    height: 80%;
    width: 20%;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-btn-baixar .btn-det:hover{
    background-color: blue;
}

.div-btn-baixar .btn-baixar{
    border: 0;
    background-color: var(--blue);
    height: 80%;
    width: 60%;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.div-btn-baixar .btn-baixar:hover{
    background-color: blue;
}

.div-btn-baixar .btn-desfazer{
    border: 0;
    background-color: var(--blue);
    height: 80%;
    width: 75%;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.div-btn-baixar .btn-desfazer:hover{
    background-color: blue;
}

.sp-qtd-registro{
    font-size: 11px;
    font-style: italic;
    padding: 5px 0px 5px 0px;
}


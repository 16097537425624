 
.div-container-proc{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    height: calc(100vh - 150px);
}

.div-itens-proc{
    width: 100%;
    max-width: 1280px;
    height: 80px;
    margin-top: 20px;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 20px;
}

.div-inputs #select-forma{
    border-color: #ccc;
}

.div-btn-add{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-btn-add button{
    height: 40px;
    width: 100%;
    background-color: var(--green100);
    border-radius: 5px;
    font-size: 15px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.div-btn-add button:hover{
    background-color: green;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.3);
}

.gridview-header-procedimento{
    background-color: var(--marrom);
    color: white;
    height: 35px;
    max-width: 900px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.gridview-header-procedimento > span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-right: 1px solid #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
}

.pr1{
    width: 10%;
}

.pr2{
    width: 50%;
}

.pr3{
    width: 10%;
}

.pr4{
    width: 10%;
}

.pr5{
    width: 10%;
}

.pr6{
    width: 10%;
}

.gridview-body-procedimento{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
}

.center{
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
.div-dados{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.div-dados label, .div-itens-proc label{
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
}

.div-dados2{
    display: flex;
    margin-top: 10px;
}

.div-itens-proc{
    display: flex;
    padding: 10px;
    overflow-y: auto;
    width: 700px;
    border-top: 2px solid rgba(0,0,0,0.2);
}

.div-gridview-proc{
    margin-left: 10px;
    width: 45%;
}

.gridview-header-procedimento{
    background-color: var(--marrom);
    color: white;
    height: 25px;
    width: 770px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pr1{
    width: 70px;
}

.pr2{
    width: 300px;
}

.pr3{
    width: 110px;
}

.pr4{
    width: 70px;
}

.div-linha-proced{
    display: flex;
    flex-direction: row;
    height: 45px;
    width: 770px;
}

.div-linha-proced:nth-child(even) {
    background-color: #fff;
}

.div-linha-proced > span > img{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
}

.div-linha-proced:hover{
    background-color: var(--gray-50);
}

.boxbtnproc{
    margin: 0px 0px 10px 10px;
    display: flex;
    flex-direction: row;
}

.boxbtnproc > button{
    margin-right: 20px;
    border: 0;
    height: 35px;
    width: 100px;
    background-color: brown;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxbtnproc > button:hover{
    background-color: red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.boxbtnproc > button > svg{
    margin-right: 5px;
}

.boxbtnproc > button#salvar{
    background-color: #22B14C;
}

.boxbtnproc > button#salvar > svg{
    margin-right: 5px;
}

.boxbtnproc > button#salvar:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
} */
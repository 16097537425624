
.input{
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
    color: black;
}

.input::placeholder{
    color: rgba(0,0,0,0.5);
}

.inputarea{
    resize: none;
    height: 100px;
    border-radius: 5px;
    padding: 5px;
}
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

.boasvindas-container{
    height: 500px;
    width: 100%;
    margin-top: 5px;
    background-color: #fff;
}

.boasvindas-topo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.boasvindas-topo div{
    display: flex;
    flex-direction: column;
}

.boasvindas-topo div h2{
    font-size: 36px;
    text-align: justify;
    padding: 0 20px 0 20px;
    margin-bottom: 30px;
    font-family: "Kalam", serif;
    font-weight: 700;
    font-style: normal;
}

.boasvindas-topo div a{
    width: 70%;
    height: 50px;
    margin: 0 auto;
    background-color: #6DE04A;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.5);
    font-size: 20px;
    font-weight: bold;
    color: #000;
}

.boasvindas-topo div a:hover{
    color: #fff; 
    background-color: var(--blue);
}

.boasvindas-txtbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6DE04A;
    height: 35px;
    width: 80%;
    margin: 0 auto;
    border-radius: 25px;
    opacity: 0;
}

.boasvindas-txtbtn span{
    color: #000;
    font-weight: bold;
    font-size: 13px;
}

.boasvindas-topo img{
    width: 400px;
}

/* MOBILE */

@media screen and (max-width: 980px) {
    
    .boasvindas-container{
        height: auto;
    }

    .boasvindas-topo{
        flex-direction: column-reverse;
        justify-content: flex-start;
        padding-top: 5px;
        height: auto;
    }

    .boasvindas-topo div h2{
        font-size: 18px;
        padding-top: 10px;
    }

    .boasvindas-topo div a{
        display: none;
    }

    .boasvindas-topo img{
        width: 300px;
    }

    .boasvindas-txtbtn{
        opacity: 1;
        margin-bottom: 10px;
    }
}
.button{
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: var(--blue100);
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.button:hover{
    background-color: var(--blue);
    color: #FFF;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
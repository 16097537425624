

.container404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container404 > span{
    font-size: 32px;
    font-family: cursive;
    font-weight: bold;
    margin-bottom: 30px;
    color: var(--blue);
}

.boxNotfound{
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxNotfound > h1{
    font-size: 40px;
    margin-top: 8px;
}

.boxNotfound > span{
    font-size: 40px;
    margin: 0px 20px;
}

.boxNotfound > h3{
    font-style: italic;
    margin-top: 8px;
}
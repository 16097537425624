
.menumobile-box{
    background-color: var(--blue100);
    width: 0%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
    transition: width 1s;
    overflow: hidden;
    z-index: 99;
}

.active{
    width: 90%;
    transition: width 1s;
}

.menumobile-box > div{
    width: 100%;
    height: 50px;
}

.menumobile-box > div > button{
    position: absolute;
    right: 0;
    height: 40px;
    width: 50px;
    border: 0;
    background-color: transparent;
    margin-top: 5px;
}

.menumobile-box ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    width: 80%;
    margin: 0 auto;
}

.menumobile-box ul li{
    background-color: var(--blue);
    border-radius: 30px;
    width: 100%;
    height: 65px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    cursor: pointer;
}

.menumobile-box a{
    color: #fff;
    margin-top: 4px;
    font-size: 12px;
}




.loginboxContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loginsection{
    width: 100%;
    height: 100vh;
    display: flex;
}

.img-login{
    background-image: url('../../assets/fundologin.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-login > h3{
    color: white;
    margin: 20px 0px;
    font-size: 28px;
    font-style: italic;
    font-family: cursive;
}

.img-login > h6{
    color: white;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 30px;
}

.img-login > span{
    color: #fff;
    font-size: 11px;
    font-style: italic;
}

.img-login > span > a{
    color: white;
}

.img-login > span > a:hover{
    text-decoration: underline;
}

.div-login{
    background-color: white;
    height: 100vh;
    width: 50%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}

.loginboxform{
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
}

form.loginboxform a span{
    color: black;
    font-weight: bold;
}

.loginbox-text-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;

}

.loginbox-text-info > h4{
    font-size: 18px;
    font-style: italic;
}

.loginbox-text-info > h6{
    font-size: 12px;
    font-style: italic;
}

.logincontainerinput{
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.div-input{
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 5px;
    gap: 5px;
}

.div-input > svg{
    margin-left: 5px;
}

.div-input > svg:hover{
    cursor: pointer;
}

.div-input > input{
    width: 100%;
    border: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

#inputpass{
    text-transform: none !important;
}

.loginboxform > button{
    width: 80%;
    margin-top: 5px;
    margin-bottom: 50px;
}

.loginboxform > a{
    color: rgba(0, 0, 0, 0.7);
    font-style: italic;
}

.loginboxform > a:hover{
    text-decoration: underline;
}

.input-codigo{
    margin-top: 40px;
    width: 90%;
    max-width: 600px;
}

.input-codigo > input{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.input-codigo > button{
    border: 0px;
    height: 107%;
    width: 110px; 
    background-color: #2322AE;
    color: white;
    font-size: 14px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;   
}

.load-icon{
    animation-name: animateloading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes animateloading{
    from{
        rotate: 0deg;
    }
    to{
        rotate: 360deg;
    }
}

section.container-anamnese{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
}

.box-anamnese{
    position: fixed;
    top: 5%;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 90%;
    width: 47%;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
}

.grupo-btn{
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    flex-direction: row;
    display: flex;
    align-items: center;
}

.grupo-btn > button:not(:last-child){
    margin-right: 8px;
}

.load-ficha{
    display: flex;
    align-items: center;
}

.load-ficha > svg{
    margin: 0px 10px;
    animation: loadingsvg 2s infinite alternate;
    -webkit-animation: loadingsvg 2s infinite alternate;
}

.grupo-btn > button{
    border: 0;
    background-color: red;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 15px;
}

.grupo-btn:first-child{
    margin-left: 5px;
}

.grupo-btn > #cancelar > svg{
    margin-top: 3px;
}

.grupo-btn > #cancelar:hover{
    transform: scale(1.1);
}

.grupo-btn #imprimir svg{
    margin-right: 5px;
}

.grupo-btn #salvar svg{
    margin-right: 5px;    
}

.grupo-btn > #salvar, #imprimir{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grupo-btn > #salvar{
    background-color: #22B14C;
}

.grupo-btn > #salvar:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.grupo-btn > #imprimir{
    background-color: #6060E0;
}

.grupo-btn > #imprimir:hover{
    background-color: #2322AE;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.txtLabel{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.txtLabel > span{
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
}

.logo-clinica{
    width: 80%;
    height: 150px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.logo-clinica > img{
    width: 500px;
    height: 140px;
    object-fit: cover;
}

.box-info{
    margin-left: 18px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
}

.box-info > span, .box-info > label{
    font-weight: bold;
}

.box-info > p:not(:last-child){
    margin-bottom: 10px;
}

.box-info > select{
    height: 35px;
    border-radius: 5px;
    margin-right: 15px;
}

.box-saude{
    border: 1px dashed black;
    height: 1790px;
    width: 95%;
    margin: 10px auto;
    border-radius: 4px;
}

.box-saude > span{
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
    text-decoration: underline;
    margin-left: 15px;
    padding-top: 5px;
}

.box-saude > span:last-child{
    position: absolute;
    right: 0;
    margin-right: 45px;
    text-decoration: overline;
}

.infoSaude > ul{
    margin: 15px;
    list-style: decimal;
    list-style-position: inside;
}

.infoSaude > ul > li{
    flex-direction: column;
    font-size: 14px;
}

.infoSaude > ul > li > span{
    margin-left: 3px;
}

.infoSaude > ul > li > textarea{
    margin-top: 5px;
    width: 100%;
}

.infoSaude > p{
    font-size: 14px;
    margin: 0 15px;
}

.infoSaude > p:last-child{
    margin-top: 10px;
}

.info-doenca{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}




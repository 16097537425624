

.header{
    background: linear-gradient(45deg, var(--blue), var(--blue100), var(--marrom));
    height: 70px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menusvg{
    margin-left: 9px;
    display: flex;
    align-items: center;
}

.menusvg > button{
    border: 0;
    background-color: transparent;
}

.pipe{
    width: 1px;
    background-color: white;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
}

.infouser{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.infouser > span{
    color: white;
    font-family: cursive;
    font-size: 22px;
}

.infouser > p{
    color: white;
    font-size: 12px;
    font-style: italic;
}

.div-header-notificacoes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 300px;
}

.icon-notificacoes{
    padding-right: 20px;
    padding-bottom: 5px;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.icon-notificacoes .qtd{
    position: absolute;
    cursor: pointer;
}

.qtd{
    background-color: brown;
    color: #fff;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    font-size: 12px;
    font-weight: bold;
    display: none;
    align-items: center;
    justify-content: center;
}

.icon-notificacoes svg{
    cursor: pointer;
}

.boxavatar{
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.boxavatar > img{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.boxavatar > svg{
    margin-left: 5px;
}


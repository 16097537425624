
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

.headerhome-container{
    background-color: var(--blue);
    height: 120px;
    width: 100%;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.6);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.headerhome-container ul{
    list-style: none;
    display: flex;
}

.headerhome-topo{
    background-color: var(--blue100);
    height: 40px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 99;
}

.headerhome-topo > ul{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    gap: 40px;
}

.headerhome-topo ul > li, .headerhome-menu nav ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.headerhome-topo ul > li:last-child{
    background-color: var(--green900);
    width: 200px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
}

.headerhome-topo ul > li:last-child:hover{
    background-color: var(--blue);
}

.headerhome-topo ul > li:last-child > a{
    font-weight: bold;
    font-size: 12px;
    text-decoration: none !important;
    color: #000;
}

.headerhome-topo ul > li:last-child:hover > a{
   color: #fff !important;
}

.headerhome-topo ul > li.acessar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
}

.headerhome-topo ul > li > a:hover{
    text-decoration: underline;
}

.headerhome-topo ul li, .headerhome-topo ul li a, .headerhome-menu nav ul li a{
    color: #fff;
}

.headerhome-full{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
    height: 120px;
}

.headerhome-Logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 150px;
    cursor: pointer;
    transition: all 0.5s;
}

.headerhome-Logo:hover{
    transform: scale(1.05);
    transition: all 0.5s;
}

.headerhome-Logo > h3{
    color: white;
    font-family: "Kalam", serif;
    font-size: 22px;
    font-weight: bold;
}

.headerhome-menu nav ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.headerhome-menu nav ul li{
    height: 50px;
    width: 105px;
    border-radius: 15px;
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: bold;
}

.headerhome-menu nav ul li:hover{
    background-color: var(--blue100);
    transition: all 0.5s;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.4);
}

.headerhome-menu nav ul li a:hover{
    text-decoration: underline;
}

.headerhome-menu-mobile{
    display: none;
    cursor: pointer;
    margin-left: 20px;
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .headerhome-menu-mobile{
        display: block;
    }    

    .headerhome-topo, .headerhome-menu{
        display: none;
    }  
    
    .headerhome-full{
        margin-top: 0;
    }

    .headerhome-Logo{
        position: absolute;
        right: 0;
        margin-right: 10px;
    }

    .headerhome-Logo:hover{
        transform: scale(1);
    }
}






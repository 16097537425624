
.content-agenda{
    background-color: white;
    position: fixed;
    width: 45%;
    height: 65%;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.grupo-btn-agenda{
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    flex-direction: row;
    display: flex;
    align-items: center;
}

.grupo-btn-agenda > button:not(:last-child){
    margin-right: 8px;
}

.grupo-btn-agenda > button.btnClose{
    border: 0;
    background-color: red;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnClose:hover{
    transform: scale(1.1);
}

.btnAgendar{
    border: 0;
    background-color: #22B14C;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.btnAgendar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnAgendar > svg{
    margin-right: 5px;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.btnClose > svg{
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.btnAgendar:hover{
    background-color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.form-agenda{
    margin-left: 20px;
}

.header-agenda{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 95%;
}

.header-agenda span{
    font-size: 16px;
    font-style: italic;
}

.nome-paciente{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.aguarde-paciente{
    border: 1px solid #ccc;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
}

.aguarde-paciente > span{
    display: flex;
    align-items: center;
}

.aguarde-paciente > span > svg{
    margin-right: 5px;
    animation: loadingsvg infinite linear 2s;
}

.div-select-nome{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.div-select-tipo{
    display: flex;
    flex-direction: column;
    width: 20%;
}

.div-select-nome label, .div-select-tipo label, .data-atendimento span{
    font-size: 14px;
    font-style: italic;  
    cursor: default !important;      
}

.div-select-nome > select{
    width: 90%;
    height: 35px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 5px;
}

.div-select-tipo > select{
    width: 90%;
    height: 35px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 5px;
}

.div-select-nome > select:focus{
    background-color: var(--gray-50);
}

.data-atendimento{
    display: flex;
    flex-direction: column;
}

.data-atendimento input{
    height: 30px;
    cursor: pointer;
    font-size: 14px;
}

.data-atendimento > input:last-child{
    margin-top: 3px;
}

.servico-executar{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 20px;
}

.servico-executar span{
    font-size: 14px;
    font-style: italic;  
    cursor: default !important;  
}

.servico-executar > textarea{
    height: 120px;
}

@keyframes loadingsvg {
    from{
        rotate: 0deg;
    }
    to{
        rotate: 360deg;
    }
}

.opcoes-menu{
    background-color: #F0F0F0;
    position: absolute;
    top: 75px;
    left: 5px;
    width: 405px;
    height: 265px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
    transition: all 0.2s;
    z-index: 99;
    overflow-y: auto;
    border: 5px solid #2A2AB4;
}

.divisao-menu{
    display: flex;
}

.opcoes{
    width: 175px;
    height: 211px;
}

.itens-menu{
    width: 100%;
    height: 211px;
    background-color: #fafafa;  
}

.opcao{
    border-right: 2px solid #ccc;
    height: 35px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
}

.opcao > div{
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.opcao:hover{
    background-color: rgba(255,202,102,0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.opcao > div > span{
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #000;
}

.opcao > svg{
    margin-left: 15px;
}

.foo{
    border-bottom: 2px solid #ccc;
}

.sair{
    height: 45px;
    background-color: #ddd;
}